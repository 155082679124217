<template lang="html">
    <div class="layout--main">
      <div class="col-md-12 py-10 precalifica-inmob">

        <!-- <router-link to="/homeInmo">-->
          <button type="button" class="btn regresar w-48 flex" @click="updatePanel()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 30 22" fill="none">
              <path d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z" fill="#FF7150"/>
            </svg>
            <span class="ml-4">
              Regresar
            </span>
          </button>
        <!-- </router-link> -->

      <div class="row mt-8 p-4 seccion-home-inmo">
        <span class="mb-4">
          Precalificaci&oacute;n online
        </span>
        <div class="row flex flex-row">
          <div class="col-sm-7 flex flex-col sm:flex-row precalifica-inmob-acceso">
            <label class="mb-1 sm:mr-8 sm:mb-0">Acceso </label>
            <div class="input-group mb-3">
              <input type="text" v-model="URLPrecalificacionOnline" class="form-control" placeholder="URL" aria-label="URL" aria-describedby="button-addon2" ref="precalificacionOnline" readonly>
              <button class="btn btn-outline-secondary bg-btn-copiar" type="button" id="button-addon2" @click="copiarLiga()">Copiar</button>
            </div>
          </div>
        </div>
      </div> 

      <ColumnConfig :filtroCasos="1" :etiqueta="'Precalifica'" :tabla=4 v-on:colsSelected="updateTable( $event )"/>

    

    
      <div id="tablapreca" class="precalifica-inmob-tabla">
        <vs-table                    
          pagination
          max-items="10"
          noDataText="No hay datos para mostrar"
          :data="casos">      
          <template slot="thead">
            <vs-th    v-if="searchColTable('Folio')">           Folio           </vs-th>
            <vs-th    v-if="searchColTable('Origen')">          Origen          </vs-th>
            <vs-th    v-if="searchColTable('Fecha ingreso')">   Fecha ingreso   </vs-th>
            <vs-th    v-if="searchColTable('Ejecutivo')">       Ejecutivo       </vs-th>
            <vs-th    v-if="searchColTable('Organización')">    Organizacion          </vs-th>
            <vs-th    v-if="searchColTable('Cliente')">         Cliente         </vs-th>
            <vs-th    v-if="searchColTable('Contacto')">        Contacto        </vs-th>
            <vs-th    v-if="searchColTable('Destino')">         Destino         </vs-th>
            <vs-th    v-if="searchColTable('Valor Inmueble')">  Valor inmueble  </vs-th>

            <!--<vs-th    >  Folio           </vs-th>
            <vs-th    >  Origen          </vs-th>
            <vs-th    >  Fecha ingreso   </vs-th>
            <vs-th    >  Ejecutivo       </vs-th>
            <vs-th    >  Perfil          </vs-th>
            <vs-th    >  Cliente         </vs-th>
            <vs-th    >  Contacto        </vs-th>
            <vs-th    >  Destino         </vs-th>
            <vs-th    >  Valor inmueble  </vs-th>-->
          </template>

          <template slot-scope="{data}">
            <vs-tr class="tabla-bg-color" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="data[indextr].id" v-if="searchColTable('Folio')">
                {{data[indextr].Id}}
              </vs-td>

              <vs-td :data="data[indextr].id" v-if="searchColTable('Origen')">
                {{data[indextr].VersionOrigen}}
              </vs-td>

              <vs-td :data="data[indextr].id " v-if="searchColTable('Fecha ingreso')">
                {{data[indextr].Fecha}}
              </vs-td>

              <vs-td :data="data[indextr].id" v-if="searchColTable('Ejecutivo')">
                {{data[indextr].BrokerNombre}}
              </vs-td>
              
              <vs-td :data="data[indextr].id" v-if="searchColTable('Organización')">
                {{data[indextr].Organizacion}}
              </vs-td>
              
              <vs-td :data="data[indextr].id" v-if="searchColTable('Cliente')">
                {{data[indextr].SolicitanteNombre}}
              </vs-td>
              
              <vs-td :data="data[indextr].id" v-if="searchColTable('Contacto')">
                <a :href="'https://api.whatsapp.com/send?phone='+data[indextr].Lada+data[indextr].SolicitanteTelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')" rel="noopener" target="_blank"  class="contact-bold" ><img class="mr-2 w-6 h-6" src="@/assets/iconos/wats_icon.png" alt=""></a>
                <a :href="'mailto:' + data[indextr].Solicitante" class="contact-bold"><img class="w-6 h-6" src="@/assets/iconos/mail.png" alt=""></a>
              </vs-td>
              
              <vs-td :data="data[indextr].id" v-if="searchColTable('Destino')">
                {{data[indextr].DestinoNombre}}
              </vs-td>
              
              <vs-td :data="data[indextr].id" v-if="searchColTable('Valor Inmueble')">
                {{data[indextr].MontoSolicitado}}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

      
    </div>
  </div>

  

</template>

<script>
import ColumnConfig from '@/components/customcols/InmobiliariaCustomCols.vue'

export default {
  components: {        
    ColumnConfig
  },
  data:()=>({    
    URLPrecalificacionOnline: '',
    columnasSelected: [],
    key: '',
    casos:[],
    destinos:[],
    estatus:[],
    perfil: []
  }),
  mounted(){
    if(!JSON.parse(localStorage.getItem('userInfo'))){
      this.$router.push('/login').catch(() => {})
    }else {
      this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
    }
    this.getDestinos()
    this.getCasosPrecalificacion()
    this.activaPanel()
    
    this.$vs.loading({      
      scale: 0.6
    })

  },
  methods: {
    activaPanel(){
      this.$emit('actualiza_panel', 'HomeInmo', 'Precalifica')
    },
    updatePanel(){
      console.log("he actualizado bande");
      this.$emit('actualiza_panel', 'Precalifica')
      this.$router.push('/homeInmo').catch(() => {})      
    },
    updateTable( cols ) {      
        this.columnasSelected = cols;                
    },
    searchColTable( col ) {       
        let isEnabled = this.columnasSelected.find(e => e.label == col);
        return isEnabled;
    },   
    copiarLiga(){
		const element = this.$refs.precalificacionOnline;
      	element.select();
		element.setSelectionRange(0, 99999);
		document.execCommand('copy');
		},
    getDestinos(){
      let self=this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.destinos=response.data.objContenido
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
    getCasosPrecalificacion(){      
      let objRequestGetCasosPrecalifica = {
        strApiKey: this.key,        
				strMetodo: 'ListaCasosPorFiltro',
        objFiltro:{
          filtroCasos: 7 // filtro de precalificacion online - Solo usuarios de tipo inmobiliario
        }			
      }
      this.$axios.post('/', objRequestGetCasosPrecalifica, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.casos= response.data.objContenido   
            let PerfilSolicitante

						for (var i = 0; i < this.casos.length; i++) {
              PerfilSolicitante  = []
							this.casos[i].MontoSolicitado= this.casos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.casos[i].Destino) {
										this.casos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}						

             /* if (this.casos[i].ExtrasVentaDigital) {                              
                  var arrayExtrasVentaDigital = this.casos[i].ExtrasVentaDigital.split(',')
                  //console.log(arrayExtrasVentaDigital)
                  
                  for (let index = 0; index < arrayExtrasVentaDigital.length; index++) {              
                    if (arrayExtrasVentaDigital[index].search('Perfil')) {     
                      PerfilSolicitante = arrayExtrasVentaDigital[index].split(':')                   
                    }
                  }
                  this.casos[i].Perfil = PerfilSolicitante[1]                                          
              }else{
                this.casos[i].Perfil = '' 
              }    */                                          
            }
            this.$vs.loading.close()
          }
          else{
            this.$vs.notify({
              title: 'Ocurrio un error en ListaCasosPorFiltro',
              text: response.data.strError,
              color:'danger',
              position: 'top-right'
            })
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.seccion-home-inmo{
    background-color:#F2F2F2;
    border-radius: 7px;
    padding: 15px;
    margin-bottom: 20px;
}
.collapse{
  margin-left: 0px !important;
}
.items-filtrado{
  border: solid 1px #8B8B8B;
  width: 79.8%;
  padding: .3rem;
  z-index: 1;
  background-color: white;
}
.item-configuracion{
  padding-left: .5rem;
  padding-right: .5rem;
  background-color: #F2F2F2;
  align-items: center;
  border-radius: 5px;
  height: 25px;
}
.font-item{
  font-size: 10px;
  text-align: center;
}
.btn-blue {
    color: #fff;
    background-color: #079DEF !important;
    border-color: #079DEF;
    margin-left: 0px !important;
}

input:read-only {
   background-color: white;
}

.btn-green-configura{
  color: #fff;
  background-color: #4ED176 !important;
  border-color: #4ED176;
  margin-left: 0px !important;
}
.bg-btn-copiar{
  background-color: #8B8B8B;
  color: #fff;
  border: none;
}
.regresar{
  background-color: #fff;
  color: #FF7150;
  border: none;
}
.tabla-bg-color{
  background-color:#F2F2F2;
}
</style>

<style>

div#tablapreca .vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    z-index: 200;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

div#tablapreca .vs-table--pagination .item-pagination {
    width: 30px;
    height: 30px;
    font-size: .8rem;
    border-radius: 0px;
    border: solid 1px #F2F2F2 !important;
}

div#tablapreca .vs-pagination--li {
    margin: 0px;
    background-color: white !important;
}
div#tablapreca .vs-pagination--ul {
    padding: 0;
    border-radius: 5% !important;
    background-color: white !important;
}
div#tablapreca .vs-pagination-primary .effect, .vs-pagination-primary .vs-pagination--buttons:hover {
    background-color: #4ED176 !important;
}

div#tablapreca .vuesax-app-is-ltr .vs-pagination--buttons.vs-pagination--button-prev, .vuesax-app-is-rtl .vs-pagination--buttons.vs-pagination--button-next {
    background-color: #4ED176;
}

div#tablapreca .vs-pagination--buttons {
  background-color: #4ED176;
}
div#tablapreca .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 6px 26px !important;
    margin-top: -25px;
}

div#tablapreca .vs-con-table .vs-table--header .vs-table--search i {
    left: 10px;
    margin-top: -26px;
}
div#tablapreca .vs-table--search-input {
    padding: 10px 20px !important;
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: .7rem;
    width: 200px;
    color: rgba(0,0,0,.7);
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    position: absolute;
    margin-top: -10px;
}
div#tablapreca .vs-con-table .vs-table--header .vs-table--search i {
    margin-top: -10px;
}

/************** Nuevos estilos responsivos **************/
.precalifica-inmob-tabla .con-pagination-table .vs-pagination--li.is-current .effect {
  background-color: #4ED176 !important;
  border-radius: 0 !important;
}

.precalifica-inmob-tabla .con-pagination-table .vs-pagination--li.is-current {
  color: #FFF;
}

.precalifica-inmob-tabla .vs-con-table {
  margin-right: 0;
}

@media screen and (max-width: 656px) {
  .precalifica-inmob .seccion-home-inmo,
  .precalifica-inmob .tabla-head-opciones {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 575px) {
  .precalifica-inmob-acceso {
    width: 100%;
    max-width: none;
  }
}
</style>